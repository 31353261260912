
import Meta from '~/mixins/meta'

export default {
  name: 'ProductIvestor',
  layout: 'ivestor',
  fetchOnServer: false,

  head() {
    return {
      title: 'iVestor',
      titleTemplate: this.$t('page-meta.title'),
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:  this.$t('page-meta.desc'),
        },
        {
          hid: 'og-title',
          property: 'og:title',
          content: this.$t('page-meta.title'),
        },
        {
          hid: 'og-type',
          property: 'og:type',
          content: 'website',
        },
        {
          hid: 'og-description',
          property: 'og:description',
          content:  this.$t('page-meta.desc'),
        },
        {
          hid: 'og-url',
          property: 'og:url',
          content: `https://www.dfm.ae/${this.$route.path}`,
        },
        {
          hid: 'og-image',
          property: 'og:image',
          content:`${this.$config.headImage}${this.$t('page-meta.image')}`,
        },
        {
          hid: 'twitter-title',
          property: 'twitter:title',
          content: this.$t('page-meta.title'),
        },
        {
          hid: 'twitter-description',
          property: 'twitter:description',
          content:  this.$t('page-meta.desc'),
        },
        {
          hid: 'twitter-image',
          property: 'twitter:image',
          content:`${this.$config.headImage}${this.$t('page-meta.image')}`,
        },
        {
          hid: 'twitter-card',
          property: 'twitter:card',
          content: 'summary',
        },
      ],
    };
  },

  data() {
    return {
      showModal: false,
      // faqs: this.$t('faqs'),
    }
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.path === '/investing/services/ivestor/signup-form') {
      this.showModal = true
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
  methods: {
    openBetaForm() {
      this.$refs.betaForm.showModal = true
    },
    formSubmitted() {
      // this.$refs.betaForm.showModal = false
      this.showModal = false
    },
    testMethod() {
      console.log('test')
    },
  },

}

import { render, staticRenderFns } from "./signup-form.vue?vue&type=template&id=4b5c2801"
import script from "./signup-form.vue?vue&type=script&lang=js"
export * from "./signup-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CountryDropdown: require('/vercel/path0/components/ui/CountryDropdown.vue').default,InfoBox: require('/vercel/path0/components/InfoBox.vue').default})
